import React from 'react';

const PolitiqueConfidentialite = () => {
  return (
    <div className="mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-center">Politique de confidentialité</h1>

      <p className="mb-6">
        La société E-MOTION exploitante du site <a href="http://www.e-motion.racing" className="text-blue-600 underline">www.e-motion.racing</a>, est soucieuse de la protection des données personnelles. En tant que responsable de traitement, elle définit l’usage qui est fait des données personnelles collectées et en assure la sécurité. 
      </p>
      <p className="mb-6">
        La présente politique de confidentialité pourra être modifiée à tout moment par le responsable de traitement. La version applicable est la dernière version mise en ligne sur le site internet.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Données personnelles collectées et finalités du traitement</h2>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Traitement 1</h3>
        <p><strong>Finalité du traitement:</strong> Création et utilisation d’un compte client en ligne</p>
        <p><strong>Base de traitement:</strong> consentement</p>
        <p><strong>Type de Données personnelles:</strong> civilité, prénom, nom, adresse email, numéro de téléphone, date de naissance</p>
        <p><strong>Catégorie de personnes concernées:</strong> prospects et clients</p>
        <p><strong>Durée de conservation:</strong> 5 ans à compter de la dernière activité (simple connexion au compte client, réservation)</p>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Traitement 2</h3>
        <p><strong>Finalité du traitement:</strong> Traitement de la réservation du client et information du client sur le statut de sa réservation</p>
        <p><strong>Base de traitement:</strong> contractuelle</p>
        <p><strong>Type de Données personnelles:</strong> civilité, prénom, nom, adresse email, numéro de téléphone, date de naissance</p>
        <p><strong>Catégorie de personnes concernées:</strong> clients</p>
        <p><strong>Durée de conservation:</strong> durée du contrat</p>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Traitement 3</h3>
        <p><strong>Finalité du traitement:</strong> Envoi de communications publicitaires et promotionnelles et toute information relative à l’activité d’E-MOTION par email et par sms</p>
        <p><strong>Base de traitement:</strong> consentement</p>
        <p><strong>Type de Données personnelles:</strong> civilité, prénom, nom, adresse email, numéro de téléphone</p>
        <p><strong>Catégorie de personnes concernées:</strong> prospects et clients ayant donné leur consentement</p>
        <p><strong>Durée de conservation:</strong> 3 ans à compter de la fin de la relation commerciale ou du dernier contact</p>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Traitement 4</h3>
        <p><strong>Finalité du traitement:</strong> Envoi de publicités concernant des événements futurs analogues à ceux réservés par le Client</p>
        <p><strong>Base de traitement:</strong> intérêt légitime</p>
        <p><strong>Type de Données personnelles:</strong> nom, prénom, adresse email, numéro de téléphone</p>
        <p><strong>Catégorie de personnes concernées:</strong> clients</p>
        <p><strong>Durée de conservation:</strong> 3 ans à compter de la fin de la relation commerciale ou du dernier contact</p>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Traitement 5</h3>
        <p><strong>Finalité du traitement:</strong> Fonctionnement et sécurisation du site</p>
        <p><strong>Base de traitement:</strong> intérêt légitime</p>
        <p><strong>Type de Données personnelles:</strong> adresse IP, données de connexion et données de navigation, type de navigateur</p>
        <p><strong>Catégorie de personnes concernées:</strong> utilisateurs du site</p>
        <p><strong>Durée de conservation:</strong> conformément à la politique de gestion des cookies</p>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Traitement 6</h3>
        <p><strong>Finalité du traitement:</strong> Connaissance client, statistiques, performance du site</p>
        <p><strong>Base de traitement:</strong> intérêt légitime</p>
        <p><strong>Type de Données personnelles:</strong> adresse IP, données de connexion et données de navigation, pages et produits consultés</p>
        <p><strong>Catégorie de personnes concernées:</strong> utilisateurs du site</p>
        <p><strong>Durée de conservation:</strong> 5 ans</p>
      </div>

      <h2 className="text-2xl font-semibold mb-4">Destinataires des données personnelles</h2>
      <p className="mb-6">
        La société E-MOTION fait appel à des prestataires pour la réalisation de certains services et prestations (prestataires techniques et informatiques, prestataires de paiement, prestataires marketing) qui disposent chacun de leur propre politique de confidentialité.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Transfert des données personnelles hors EEE</h2>
      <p className="mb-6">
        Vos données personnelles sont traitées et conservées au sein de l’Union Européenne (UE) ou de l’Espace Économique Européen (EEE). Dans le cas où des données personnelles vous concernant seraient transférées à des sous-traitants ou d’autres destinataires domiciliés hors de l'Union Européenne, un contrat de transfert de données personnelles sera signé avec le sous-traitant ou destinataire des données afin de garantir un niveau de protection similaire à celui assuré par la législation européenne.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Durée de conservation</h2>
      <p className="mb-6">
        La société E-MOTION conservera vos données personnelles dans un environnement sécurisé pendant toute la durée nécessaire à la réalisation des finalités pour lesquelles elles ont été collectées. Notamment, les données personnelles des clients seront conservées pendant toute la durée du contrat conclu avec le responsable de traitement, et au minimum pendant la durée prévue par la législation applicable.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Sécurité et confidentialité</h2>
      <p className="mb-6">
        Conformément à la Loi Informatique et Libertés et au RGPD, la société E-MOTION et ses partenaires, s’efforcent de mettre en place toutes les mesures techniques et organisationnelles appropriées afin de garantir un niveau de sécurité adapté au risque de vos données personnelles, et notamment permettant d’en prévenir la destruction, la perte, l’altération, la divulgation ou tout accès non autorisé.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Vos droits</h2>
      <p className="mb-6">
        Vous pouvez à tout moment adresser une demande à la société E-MOTION pour accéder et obtenir une copie de vos informations personnelles, les corriger, mettre à jour, en demander la portabilité et retirer votre consentement pour les traitements à des fins de prospection commerciale. Vous pouvez également vous opposer au traitement de vos données pour des motifs légitimes afférant à votre situation particulière, demander la limitation du traitement et décider du sort de vos données post-mortem auprès d’un tiers de confiance certifié par la CNIL.
      </p>

      <p className="mb-6">
        Pour exercer ces droits, vous pouvez nous contacter en envoyant un courrier électronique à l’adresse <a href="mailto:dpo@car-lovers.com" className="text-blue-600 underline">dpo@car-lovers.com</a>.
      </p>

      <p className="mb-6">
        Si, après nous avoir contactés, vous estimez que vos droits ne sont pas respectés, vous pouvez déposer une plainte auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL).
      </p>

      <p className="text-center text-sm text-gray-500">&copy; 2024 E-MOTION. Tous droits réservés.</p>
    </div>
  );
};


export default PolitiqueConfidentialite;
