// src/components/EmailChecker.js
import React, { useState } from 'react';
import { checkCustomer } from '../services/apiService';
import { XMLParser } from 'fast-xml-parser';
import { LoadingOverlay } from './Loading';

const EmailChecker = ({ onCustomerFound, onCustomerNotFound, searchedEmails }) => {
  const [email, setEmail] = useState('');
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [duplicate, setDuplicate] = useState(false);

  const handleCheckEmail = async () => {

    if (!email) {
      setError('Email est obligatoire');
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setError('Email invalide');
      return;
    }

     let duplicate = false;
     
     if (searchedEmails.length > 0) {
        searchedEmails.forEach((customer, i) => {
          if (customer === email) {
            duplicate = true
            return;
          }
        });
      }
    

    if (duplicate) {
      setError('Email est déjà utilisé pour un autre participant');
      return;
    }


    setLoading(true);
    setError('');
   
    try {

      const result = await checkCustomer(email);
   
      if (result && result.customer) {
        const customerData = result.customer;

        if (customerData.id) {
          const customer = {
            id: customerData.id,
            name: customerData.firstname.concat(" ", customerData.lastname),
            firstname: customerData.firstname,
            lastname: customerData.lastname,
            tel: customerData.tel,
            email: customerData.email,
            home_venue: customerData.home_venue,
            civilite: customerData.civilite
          };
          onCustomerFound(customer);
          setCustomer(customer);
          setLoading(false);
          setError('');
        } else {
          setLoading(false);
          setError('');
          onCustomerFound(null);
          onCustomerNotFound(email)
        }
      } else {
        setLoading(false);
          setError('');
        onCustomerFound(null);
        onCustomerNotFound(email)
      }

    } catch (err) {
      setError('');
      onCustomerFound(null);
      onCustomerNotFound(email)
    } finally {
      setLoading(false);
    }

   
  };

  return (
    <div>
      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
        Email
      </label>
      <input
        type="email"
        id="email"
        name="email"
        className="mt-1 block w-full shadow border pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gold focus:border-gold sm:text-sm rounded-md"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <button
        className="mt-4 w-full bg-gold text-black py-2 px-4 rounded-md hover:bg-black hover:text-gold"
        onClick={handleCheckEmail}
        disabled={loading}
      >
        {loading ? 'Checking...' : 'Vérifier l\'email'}
      </button>
      {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
      {customer && (
        <div className="mt-4">
          <h3 className="text-lg font-medium">Détails du client</h3>
          <p>Nom: {customer.name}</p>
          <p>Email: {customer.email}</p>
          {/* Add other customer details as needed */}
        </div>
      )}
      <LoadingOverlay isLoading={loading} />
    </div>
  );
};

export default EmailChecker;
