export const calculateFinalTotal = (selectedOffer, groupSize, childrenSize) => {
  const originalTotal = selectedOffer.price * groupSize;
  const isGroupDiscountApplicable = groupSize >= 4;
  const groupDiscountedTotal = isGroupDiscountApplicable ? parseFloat((originalTotal * 0.85).toFixed(2)) : originalTotal;
  const is_kid_friendly = selectedOffer.is_kid_friendly;


  let finalTotal = originalTotal;

  if(is_kid_friendly ) {
    finalTotal = parseInt((originalTotal * 0.85))
  } else {
    if (isGroupDiscountApplicable) {
      finalTotal = groupDiscountedTotal;
    } else if(childrenSize) {
      const nonChildrenCount = groupSize - childrenSize;
      const nonDiscountedAmount = selectedOffer.price * nonChildrenCount;
      const childrenDiscountedAmount = selectedOffer.price * childrenSize * 0.85;
      finalTotal = nonDiscountedAmount + childrenDiscountedAmount;
    }
  }
 

  return { finalTotal, originalTotal };
};


export const isValidBooking = (booking) => {
 return   booking.groupSize > 0 &&
          booking.selectedOffer.name &&
          booking.finalTotal > 0;
}