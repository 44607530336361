// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import store, { persistor } from './store/store';
import BookingPage from './pages/BookingPage';
import MentionsLegales from './components/static-pages/MentionsLegales';
import PolitiqueConfidentialite from './components/static-pages/PolitiqueConfidentialite';
import CGV from './components/static-pages/CGV';
import HomePage from './pages/HomePage';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/CheckoutPage';
import StaticPage from './pages/StaticPage';
import OrderConfirmationPage from './pages/OrderConfirmationPage';
import { ProtectedCheckoutRoute, ProtectedPurchaseRoute} from './components/ProtectedRoute';
import CheckoutFailedPage from './pages/CheckoutFailedPage';
import { PersistGate } from 'redux-persist/integration/react';
import { Helmet } from 'react-helmet';


const DynamicMetaTags = () => {
  const location = useLocation();

  return (
    <Helmet>
      {location.pathname === '/' ? (
        <meta name="robots" content="index, follow" />
      ) : (
        <meta name="robots" content="noindex, nofollow" />
      )}
    </Helmet>
  );
};
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <DynamicMetaTags />
          <Routes>
            <Route path="/" element={<HomePage />}>
              <Route index element={<BookingPage />} />
              <Route path="mentions-legales" element={<MentionsLegales />} />
              <Route path="politique-confidentialite" element={<PolitiqueConfidentialite />} />
              <Route path="cgv" element={<CGV />} />
              <Route path="cart" element={<CartPage />} /> 
              {/* 
              <Route 
              path="/checkout" 
              element={
                <ProtectedCheckoutRoute>
                  <CheckoutPage />
                </ProtectedCheckoutRoute>
              } 
            />
          
              <Route path="/order-confirmation" 
                  element={
                    <ProtectedPurchaseRoute>
                    <OrderConfirmationPage />
                    </ProtectedPurchaseRoute>
                } 
              />
          */}
              <Route path="checkout" element={<CheckoutPage />} /> 

              <Route path="order-confirmation" element={<OrderConfirmationPage />} /> 

              <Route path="checkout-failed" element={<CheckoutFailedPage />} /> 
              {/* Add more routes as needed */}
            </Route>
          </Routes>
        </Router>
    </PersistGate>
    </Provider>
  );
}

export default App;
