// src/components/CustomerForm.js
import React, { useState } from 'react';
import { createCustomer } from '../services/apiService';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { formatDate } from '../utils/formatDateTime';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import { LoadingOverlay } from './Loading';

registerLocale('fr', fr);
setDefaultLocale('fr');

const CustomerForm = ({ onCustomerChange, onCustomerCreated, eventDetails, customerExist, externalErrors, searchedEmail, iterationIndex}) => {
  const [name, setName] = useState('');
  const [firstname, setFirstName] = useState(customerExist?.firstname || '');
  const [lastname, setLastName] = useState(customerExist?.lastname || '');
  const [tel, setTel] = useState(customerExist?.tel || '');
  const [email, setEmail] = useState(customerExist?.email || searchedEmail || '');
  const [homeVenueId, setHomeVenueId] = useState(eventDetails.venueId);
  const [birthday, setBirthday] = useState(customerExist?.birthday || '');
  const [civilite, setCivilite] = useState('');
  const [consent, setConsent] = useState('');
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [eventNotifications, setEventNotifications] = useState(false);


  const validateField = (name, value) => {
    let errorMsg = '';
    if (!value ) {
      errorMsg = 'Ce champ est requis';
    }
    return errorMsg;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
  
    // Update the state based on the field name
    if (name === 'firstname') {
      setFirstName(value);
      //setName(value.concat(" ", lastname))
      newValue = value.concat(" ", lastname);
    } else if (name === 'lastname') {
      setLastName(value);
     // setName(firstname.concat(" ", value))
      newValue = firstname.concat(" ", value);
    } else if (name === 'tel') {
      setTel(value);
    } else if (name === 'email') {
      setEmail(value);
    }

  
    // Notify parent component of the change for validation or other purposes

  
    const inputName = ['firstname', 'lastname'].includes(name) ? 'name' : name;
    onCustomerChange(inputName, newValue);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let fieldError = '';
    if(iterationIndex < 1) {
       fieldError = validateField(name, value); 
    } else if (!['tel', 'email', 'birthday'].includes(name)) {
       fieldError = validateField(name, value); 
    }


    const errorExternelField = (externalErrors && externalErrors[name] !== undefined ? externalErrors[name] : '');

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: fieldError || errorExternelField,
    }));

    //FullName Checlks
    if (['firstname', 'lastname'].includes(name)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        'name' : (externalErrors && externalErrors['name'] !== undefined ? externalErrors['name'] : '') || '',
      }));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    setBirthday(formattedDate);
    onCustomerChange('birthday', formattedDate);
    setErrors((prevErrors) => ({
      ...prevErrors,
      birthday: validateField('birthday', formattedDate),
    }));
  };

  const handleConsentChange = (e) => {
    const selectedValue = e.target.value;
    setConsent(selectedValue);
    setEventNotifications(selectedValue === 'oui')
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {
      ...errors,
      firstname: validateField('firstname', firstname),
      lastname: validateField('lastname', lastname),
      tel: iterationIndex < 1 ? validateField('tel', tel) : '',
      email: iterationIndex < 1 ? validateField('email', email) : '',
      birthday: iterationIndex < 1 ? validateField('birthday', birthday) : '',
      civilite: validateField('civilite', civilite),
      ...( iterationIndex < 1 && {consent: validateField('consent', consent)}),
      ...( iterationIndex < 1 && {privacyPolicyAccepted: privacyPolicyAccepted ? '' : 'Vous devez accepter la politique de confidentialité'}),
    };

    setErrors(newErrors);
    
   

    const hasErrors = Object.values(newErrors).some((error) => error !== '');
    if (hasErrors) {
      return;
    }

    setLoading(true);
    setError('');
    try {
      const customerData = {
        name: firstname.concat(" ", lastname),
        civilite,
        firstname,
        lastname,
        tel,
        email,
        birthday,
        event_notifications: eventNotifications,
        home_venue_id: homeVenueId
      };
      const data = await createCustomer(customerData);
      onCustomerCreated({...data.customer});
      setIsSubmitted(true);
    } catch (err) {
      setError('Erreur lors de la création du client');
    } finally {
      setLoading(false);
    }
  };

  /*
  const handleBirthdayChange = (e) => {
    const date = parseISO(e.target.value);
    setBirthday(format(date, 'yyyy-MM-dd', { locale: fr }));
  };
  */

  if (isSubmitted) {
    return (
      <div className="p-4 border-2 border-gold rounded-md">
        <h2 className="text-lg font-medium text-gold">Inscription validée</h2>
        <p><strong>Nom:</strong> {firstname.concat(" ", lastname)}</p>
        <p><strong>Email:</strong> {email}</p>
        <p><strong>Date de naissance:</strong> {birthday && birthday}</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="my-4 space-y-4 text-black" noValidate>
      <div className="mb-4 md:mb-0">
        <div className="flex">
            <label className="block text-sm font-medium text-gray-700">Civilité <span className='text-red-500'>*</span></label>
            <div className="mt-0 ml-4">
              <label className="inline-flex items-center">
                <input type="radio" name="civilite" value="Mr" onChange={(e) => setCivilite(e.target.value)} />
                <span className="ml-2">Mr</span>
              </label>
              <label className="inline-flex items-center ml-2">
                <input type="radio" name="civilite" value="Mme" onChange={(e) => setCivilite(e.target.value)} />
                <span className="ml-2">Mme</span>
              </label>
            </div>
          </div>
          {errors.civilite && <p className="text-red-500 text-sm">{errors.civilite}</p>}
        </div>
      <div className="md:flex gap-3">
          <div className="mb-4 md:mb-0">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Nom <span className='text-red-500'>*</span>
              </label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                className="mt-1 shadow text-black border block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gold focus:border-gold sm:text-sm rounded-md"
                value={firstname}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
              {errors.firstname && <p className="text-red-500 text-sm">{errors.firstname}</p>}
              
          </div>
          <div className="mb-4 md:mb-0">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Prénom <span className='text-red-500'>*</span>
            </label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              className="mt-1 shadow border block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gold focus:border-gold sm:text-sm rounded-md"
              value={lastname}
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
            {errors.lastname && <p className="text-red-500 text-sm">{errors.lastname}</p>}
          </div>
          
      </div>

      {errors.name && <div className="text-red-500 text-sm">{firstname} {lastname} est déjà utilisé pour un autre participant</div>}
    
      <div className="md:flex gap-3">
       <div className="mb-4 md:mb-0">
          
          <label htmlFor="tel" className="block text-sm font-medium text-gray-700">
            Téléphone {iterationIndex < 1 && <span className='text-red-500'>*</span> }
          </label>
          <input
            type="text"
            id="tel"
            name="tel"
            className="mt-1 shadow border block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-gold focus:border-gold sm:text-sm rounded-md"
            value={tel}
            onChange={handleInputChange}
            onBlur={handleBlur}
            
          />
          {errors.tel && <p className="text-red-500 text-sm">{errors.tel}</p>}
        </div>
        <div className="mb-4 md:mb-0">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email {iterationIndex < 1 && <span className='text-red-500'>*</span> }
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="mt-1 shadow border block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gold focus:border-gold sm:text-sm rounded-md"
            value={email}
            onChange={handleInputChange}
            onBlur={handleBlur}
            disabled
          />
           {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
        </div>
    </div>
    <div>
      <label htmlFor="birthday" className="block text-sm font-medium text-gray-700">
        Date de naissance {iterationIndex < 1 && <span className='text-red-500'>*</span> }
      </label>
      <DatePicker
          selected={birthday}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          locale="fr"
          className="mt-1 shadow text-black border block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gold focus:border-gold sm:text-sm rounded-md"
          placeholderText="Sélectionnez une date"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          required
        />
        {errors.birthday && <p className="text-red-500 text-sm">{errors.birthday}</p>}
    </div>
    {iterationIndex < 1 && 
    <div>
    <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Souhaitez-vous être informé(e) de l’ouverture prochaine d’E-motion Paris, des évènements et compétitions ? <span className='text-red-500'>*</span></label>
        <div className="mt-2">
          <label className="inline-flex items-center">
            <input type="radio" name="consent" value="oui"  onChange={handleConsentChange} />
            <span className="ml-2">Oui</span>
          </label>
          <label className="inline-flex items-center ml-2">
            <input type="radio" name="consent" value="non"  onChange={handleConsentChange} />
            <span className="ml-2">Non</span>
          </label>
        </div>
        {errors.consent && <p className="text-red-500 text-sm">{errors.consent}</p>}
      </div>
      <div className="mb-4">
        <label className="inline-flex items-center">
          <input type="checkbox" checked={privacyPolicyAccepted} onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)} />
          <span className="ml-2">J'ai lu et accepté la <Link to="/politique-confidentialite" className="text-gold hover:underline" target='_blank'>politique de confidentialité</Link> et les <Link to="/cgv" className="text-gold hover:underline" target='_blank'>CGV</Link></span>
        </label>
        {errors.privacyPolicyAccepted && <p className="text-red-500 text-sm">{errors.privacyPolicyAccepted}</p>}
      </div>
    </div>
    }

    <div className='flex justify-end'>
        <button
          type="submit"
          className="mt-4 bg-black text-gold py-2 px-4 rounded-md hover:bg-gold hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold"
          disabled={loading}
        >
          {loading ? 'Création en cours...' : 'Valider'}
        </button>
    </div>
   
    {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
    <LoadingOverlay isLoading={loading} />
  </form>
  );
};

export default CustomerForm;
