import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { formatDate } from '../utils/formatDateTime';
import { fetchAvailableDates } from '../services/apiService';
import { parseISO } from 'date-fns';

const DateSkeletonLoader = () => (
  <div className="space-y-2">
    <div className="grid grid-cols-7 gap-2 mb-4">
      {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day, i) => (
        <div key={i} className="text-gray-500 text-sm text-center">{day}</div>
      ))}
    </div>
    <div className="grid grid-cols-7 gap-2">
      {[...Array(35)].map((_, i) => (
        <div key={i} className="animate-pulse flex items-center justify-center h-12 w-12 bg-gray-300 rounded-full"></div>
      ))}
    </div>
  </div>
);

const DatePicker = ({ onDateChange, selectedDate }) => {
  const [date, setDate] = useState(selectedDate || new Date());
  const [availableDates, setAvailableDates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadAvailableDates = async () => {
      try {
        const dates = await fetchAvailableDates();
        setAvailableDates(dates);
      } catch (error) {
        console.error('Error fetching available dates:', error);
      } finally {
        setLoading(false);
      }
    };

    loadAvailableDates();
  }, []);

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    setDate(formattedDate);
    onDateChange(formattedDate);
  };

  const formatShortWeekday = (locale, date) => {
    return format(date, 'EEEEEE', { locale: fr }); // Short weekday format in French
  };

  const formatMonthYear = (locale, date) => {
    return format(date, 'MMMM yyyy', { locale: fr }); // Month and year format in French
  };

  const tileDisabled = ({ date, view }) => {
    if (view === 'month') {
      const formattedDate = formatDate(date);
      return !availableDates.includes(formattedDate); // Disable dates not in availableDates array
    }
    return false;
  };


  const firstAvailableDate = availableDates.length > 0 ? parseISO(availableDates[0]) : new Date();

  return (
    <div>
      {loading ? (
        <DateSkeletonLoader />
      ) : (
        <Calendar
          onChange={handleDateChange}
          value={new Date(date)}
          locale="fr-FR"
          formatShortWeekday={formatShortWeekday}
          formatMonthYear={formatMonthYear}
          tileDisabled={tileDisabled} // Disable unavailable dates
          minDate={firstAvailableDate}
          className="react-calendar rounded-lg shadow-md"
        />
      )}
    </div>
  );
};

export default DatePicker;