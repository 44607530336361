import BookingPage from "./BookingPage";
import sportsCarImage from '../assets/motion-driver.jpg'; // Assurez-vous que le chemin est correct
import logoImage from '../assets/emotion-logo.png';
import Footer from "../components/Footer";
import { Link, Outlet } from "react-router-dom";
import { selectCartItemSize }  from '../store/cartSlice'
import { useSelector } from 'react-redux';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';



const HomePage = () => {
  const cartItemCount = useSelector(selectCartItemSize);

  return (
      
    <div className="bg-gray-100 min-h-screen flex flex-col bg-cover bg-center bg-gradient-to-b from-gold to-black font-roboto">
      <header className="bg-white shadow sticky top-0 z-50">
    <div className="container mx-auto px-6 py-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Link to="/">
            <img src={logoImage} alt="Logo" className="h-8" />
          </Link>
        </div>
        <div>
           <Link to="/cart" className="relative text-gray-800 hover:text-gold">
           <ShoppingCartIcon className="h-8 w-8" />
            {cartItemCount > 0 && (
              <span className="absolute top-4 left-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-gold rounded-full">
                {cartItemCount}
              </span>
            )}
            </Link>
        </div>
      </div>
    </div>
  </header>
  <div id="app" className="flex-grow container mx-auto p-4 md:p-8 font-roboto">
      <div className="bg-white p-4 md:p-6  rounded-lg shadow-md">
        <Outlet />
      </div>
    </div>
    <Footer />
 </div>
  )
};

export default HomePage;