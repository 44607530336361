// src/components/BookingForm.js
import React, { useState } from 'react';
import { createBooking } from '../services/apiService';
import { bookedError, bookOffer } from '../utils/booked';
import { formatDateTime } from '../utils/formatDateTime';
import { addMinutes, parseISO } from 'date-fns';
import { LoadingOverlay } from './Loading';

const BookingForm = ({ customers, eventDetails, bookingdate, groupSize, childrenSize, offer, onBookingSuccess}) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleStartDateTimeChange = (e) => {
    const formattedDateTime = formatDateTime(e.target.value);
    setStartDate(formattedDateTime);
  };

  const handleEndDateTimeChange = (e) => {
    const formattedDateTime = formatDateTime(e.target.value);
    setEndDate(formattedDateTime);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
     
      const bookingData = await bookOffer({ customers, eventDetails, bookingdate, groupSize, childrenSize, offer });
      onBookingSuccess(bookingData);
      //onBookingSuccess();
    } catch (error) {
      const messageError = bookedError(error);
      setError(messageError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="my-4">
      
      <button
        type="submit"
        className="w-full bg-black text-gold hover:bg-gold hover:text-black py-2 px-4"
        disabled={loading}
      >
        {loading ? 'Réservation en cours...' : 'Réserver'}
      </button>
      {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
      <LoadingOverlay isLoading={loading} />
    </form>
  );
};

export default BookingForm;
