import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {  storePurchaseDetails } from '../store/purchaseSlice';
import { clearCart, selectBookingIds, selectCustomerId } from '../store/cartSlice';
import { RadioGroup } from '@headlessui/react';
import { createPayment, updatePaymentStatus } from '../services/apiService';


const CheckoutForm = ({finalTotal}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cartItems = useSelector((state) => state.cart.items);

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentMethodType, setPaymentMethodType] = useState('stripe');
  const [sylqPaymentType, setSylqPaymentType] = useState('bank');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const bookingIds = useSelector(selectBookingIds);
  const customerId = useSelector(selectCustomerId);

  const amountInCents = Math.round(finalTotal * 100);


  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'FR',
        currency: 'eur',
        total: {
          label: 'Total',
          amount: amountInCents, // Amount in cents
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on('paymentmethod', async (e) => {


        try {
          const paymentData = {
            amount: finalTotal,
            customerId: customerId,
            bookingIds: bookingIds,
            provider: 'stripe',
            options: {
              paymentMethodId: e.paymentMethod.id,
            }
          };

          const paymentResponse = await createPayment(paymentData);

          const clientSecret = paymentResponse.data.client_secret;
          const paymentId = paymentResponse.data.paymentId;

          const { error, paymentIntent } = await stripe.confirmCardPayment(
            clientSecret,
            { payment_method: e.paymentMethod.id },
            { handleActions: false }
          );

          let payementSuccessStatus;

          if (error) {
            e.complete('fail');
            setError(error.message);
            payementSuccessStatus = 'failed'; 
          } else {
            e.complete('success');
            setPaymentSuccess(true);
            setPaymentIntent(paymentIntent)
            payementSuccessStatus = 'completed'; 
          }

          try {
            await updatePaymentStatus(paymentId, payementSuccessStatus);
          } catch (statusError) {
            console.error('Failed to update payment status:', statusError.message);
          }


        } catch(error) {
          setError('Error initiating Stripe payment');
        }

      });
    }
  }, [stripe]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setError(error.message);
        setLoading(false);
      } else {
        
        try {
          const paymentData = {
            amount: finalTotal,
            customerId: customerId,
            bookingIds: bookingIds,
            provider: 'stripe',
            options: {
              paymentMethodId: paymentMethod.id,
            }
          };

          const paymentResponse = await createPayment(paymentData);

          const paymentId = paymentResponse.data.paymentId;
          const clientSecret = paymentResponse.data.client_secret;

          const { error, paymentIntent } = await stripe.confirmCardPayment(
            clientSecret,
            { payment_method: paymentMethod.id }
          );

          let payementSuccessStatus;
          if (error) {
            setError(error.message);
            payementSuccessStatus = 'failed'; 
          } else {
            setPaymentSuccess(true);
            setPaymentIntent(paymentIntent)
            payementSuccessStatus = 'completed'; 
          }

          try {
            await updatePaymentStatus(paymentId, payementSuccessStatus);
          } catch (statusError) {
            console.error('Failed to update payment status:', statusError.message);
          }

        } catch(error) {
          setError('Error initiating Stripe payment');
        }

        setLoading(false);
      }
  };

  const handlePayWithCrypto = async () => {

    setLoading(true);

    try {
      const paymentData = {
        amount: finalTotal,
        customerId: customerId, 
        bookingIds: bookingIds, 
        provider: 'sylq',
        options: {
          callbackUrlOk: `${window.location.origin}/order-confirmation`,
          callbackUrlNotOk: `${window.location.origin}/checkout-failed`,
        }
      };

      const paymentResponse = await createPayment(paymentData);

      if (paymentResponse.data && paymentResponse.data.checkout_url) {
         // Save the payment details before redirecting
        localStorage.setItem('sylqPayment', JSON.stringify({
          paymentId: paymentResponse.data.paymentId,
          reference: paymentResponse.data.reference,
          status: paymentResponse.data.status,
        }));
        
        window.location.href = paymentResponse.data.checkout_url;
      } else {
        setError('Failed to initiate Sylq payment');
      }
    } catch (error) {
      setError('Error initiating Sylq payment');
    } finally {
      setLoading(false);
    }

  };


  useEffect(() => {
    if (paymentSuccess) {

      // Store purchase details after payment success
      dispatch(storePurchaseDetails({
        cartItems: cartItems,
        finalTotal: finalTotal,
        paymentIntentId: paymentIntent.id,  // Ensure paymentIntentId is accessible here
      }));

      // Clear the cart
      dispatch(clearCart());

      const paymentSylqExist = localStorage.getItem('sylqPayment');
      if (paymentSylqExist) {
        localStorage.removeItem('sylqPayment');
      }

      navigate('/order-confirmation'); // Redirect to a confirmation page after successful payment
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSuccess, dispatch, navigate]);

  return (
    <div className="mx-auto p-6 bg-white rounded-lg shadow-lg">
       <h2 className="text-2xl font-bold text-gray-900 mb-4">Paiement en ligne 100% sécurisé</h2>


      {paymentSuccess ? (
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gold">Merci pour votre réservation!</h2>
          <p className="mt-4 text-gray-700">Votre paiement a été traité avec succès.</p>
        </div>
      ) : (
        <div>
          {paymentRequest && (
            <div className="mb-4">
              <PaymentRequestButtonElement options={{ paymentRequest }} />
            </div>
          )}
          <div className="flex items-center justify-between mb-4">
            <hr className="w-1/4 border-gray-300" />
            <span className="text-gray-500 text-nowrap px-4">Payez par carte</span>
            <hr className="w-1/4 border-gray-300" />
          </div>
          <div className="flex justify-center flex-wrap sm:flex-nowrap space-x-2 sm:space-x-4 mb-4">
            <img src="/images/cards/gpay.png" alt="google Pay" className="h-11 w-auto" />
            <img src="/images/cards/apay.png" alt="Apple Pay" className="h-11  w-auto" />
            <img src="/images/cards/card_visa.png" alt="Visa" className="h-10 w-auto" /> 
            <img src="/images/cards/mastercard.png" alt="Mastercard" className="h-10 w-auto" />
            <img src="/images/cards/amex-logo.png" alt="American Express" className="h-10 w-auto" />
            <img src="/images/cards/logo-cb.jpg" alt="Carte Bancaire" className="h-10 w-auto" />
          </div>
          <form onSubmit={handleSubmit} className="mb-4">
            {paymentMethodType === 'stripe' && (
            <div className="mb-4">
              <CardElement
                className="p-3 border border-gray-300 rounded-md"
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#32325d',
                      '::placeholder': {
                        color: '#a0aec0',
                      },
                    },
                    invalid: {
                      color: '#fa755a',
                    },
                  },
                }}
              />
            </div>
            )}
            {error && <div className="text-red-600 mb-4">{error}</div>}
            <button
              type="submit"
              disabled={!stripe || loading}
              className={`w-full py-2 px-4 rounded-md text-black ${loading ? 'bg-gray-400' : 'bg-gold hover:bg-black hover:text-gold'} focus:outline-none`}
            >
              {loading ? 'Traitement...' : 'Payer'}
            </button>
          </form>
          <div className="flex items-center justify-between mb-4">
            <hr className="w-1/4 border-gray-300" />
            <span className="text-gray-500 text-nowrap px-4">Ou payez par Crypto</span>
            <hr className="w-1/4 border-gray-300" />
          </div>
          <button
              type="submit"
              onClick={handlePayWithCrypto}
              disabled={loading}
              className={`w-full py-2 px-4 rounded-md text-white ${loading ? 'bg-gray-400' : 'bg-gray-800 hover:bg-gray-900'} focus:outline-none`}
            >
              {loading ? 'Payer par Crypto' : 'Payer par Crypto'}
            </button> 

          <div className='mt-2 text-xs text-gray-500'>
           En effectuant le paiement, j'accepte les <Link to="/cgv" className="text-gold hover:underline" target='_blank'>conditions générales de vente</Link> E-Motion
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckoutForm;
