import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOffers } from '../services/apiService'; // Fetch offers from API

// Async action to fetch offers from the API
export const fetchOffers = createAsyncThunk('offers/fetchOffers', async () => {
 /* const response = await new Promise((resolve) => 
    setTimeout(async () => resolve(await getOffers()), 2000) // 2 seconds delay
  ); */
  const response = await getOffers();
  return response.offers;
});

const offerSlice = createSlice({
  name: 'offers',
  initialState: {
    offers: [],
    selectedOfferId: null, // Store the selected offer by ID
    status: 'idle',
    error: null,
  },
  reducers: {
    setSelectedOfferId: (state, action) => {
      state.selectedOfferId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOffers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.offers = action.payload; // Store the fetched offers
      })
      .addCase(fetchOffers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Export actions
export const { setSelectedOfferId } = offerSlice.actions;

// Export selectors
export const selectAllOffers = (state) => state.offers.offers;
export const selectOfferById = (state, offerId) => state.offers.offers.find((offer) => offer.id === offerId);
export const selectSelectedOffer = (state) => state.offers.selectedOfferId;

export default offerSlice.reducer;
