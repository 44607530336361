// src/components/ErrorMessage.js
import React, { useState, useEffect } from 'react';

const ErrorMessage = ({ message, errorKey }) => {

  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(true);
  }, [errorKey]);

  const handleHide = (e) => {
    setVisible(false);
  };

  if (!message || !visible) return null;
   
  return <div className="bg-red-100 text-red-700 font-semibold text-sm px-4 pl-8 py-3 my-4 rounded relative" role="alert">
        <span className="block sm:inline">{message}</span>
        <span className="absolute top-3.5 left-2 rounded-full bg-red-700 text-xs h-4 w-4 p-0.5" onClick={handleHide}>
            <svg className="fill-current text-white " role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Fermer</title><path d="M14.348 14.849a1 1 0 01-1.415 0L10 11.414 6.707 14.707a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707a1 1 0 011.414-1.414L10 8.586l3.293-3.293a1 1 0 011.414 1.414L11.414 10l3.293 3.293a1 1 0 010 1.414z"/></svg>
        </span>
    </div>;
};

export default ErrorMessage;
