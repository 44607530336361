import React from 'react';

const MentionsLegales = () => {
  return (
    <div className="mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-center">Mentions légales</h1>
      <h2 className="text-xl font-semibold mb-2">Editeur du site</h2>
      <p className="mb-6">
          <strong>Dénomination sociale :</strong> E-MOTION<br/>
          <strong>Forme et capital :</strong> SAS au capital de 1 000,00 €<br/>
          <strong>Siège social :</strong> 20 bis rue Louis-Philippe, 92200 Neuilly-sur-Seine<br/>
          <strong>RCS :</strong> NANTERRE 929 883 395<br/>
          <strong>N° TVA intracommunautaire :</strong> FR37929883395<br/>
          <strong>Email :</strong> <a href="mailto:contact@car-lovers.com" className="text-blue-600 underline">contact@car-lovers.com</a>
      </p>
      <h2 className="text-xl font-semibold mb-2">Directeur de la publication</h2>
      <p className="mb-6">Le directeur de la publication du site est Madame Simona CASCONE SCHUMACHER.</p>
      <h2 className="text-xl font-semibold mb-2">Hébergeur du site</h2>
      <div className="mb-6">
          <p><strong>Société :</strong> VERCEL</p>
          <p><strong>Adresse :</strong> 340 S Lemon Ave #4133, Walnut, CA 91789, USA</p>
          <p><strong>Email de contact :</strong></p>
          <p><strong></strong> <a href="mailto:support@vercel.com" className="text-blue-600 underline">support@vercel.com</a></p>
      </div>
    </div>
  );
};

export default MentionsLegales;
