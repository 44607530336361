import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-b from-white to-gray-100 text-gray-700 py-5 ">
    <div className="container mx-auto text-center flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-8">
      <Link to="/mentions-legales" className="hover:text-gold">
        Mentions légales
      </Link>
      <Link to="/politique-confidentialite" className="hover:text-gold">
        Politique de confidentialité
      </Link>
      <Link to="/cgv" className="hover:text-gold">
        CGV
      </Link>
    </div>
    <div className="mt-4 text-center">
      <p className="text-sm text-gray-500">&copy; 2024 E-motion. Tous droits réservés.</p>
    </div>
  </footer>
  
  );
};

export default Footer;
