import React, { useEffect } from 'react'
import PaymentForm from '../components/PaymentForm';
import Summary from '../components/Summary';
import { selectCartFinalTotal }  from '../store/cartSlice'
import { useSelector, useDispatch } from 'react-redux';
import { resetBooking } from '../store/bookingSlice'; 
import ProgressBar from '../components/ProgressBar';
import { useNavigate } from 'react-router-dom';

const CheckoutPage = () => {

  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const unbookedItems = cartItems.filter(item => !item.isBooked);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetBooking());
    if (unbookedItems.length > 0) {
      navigate('/cart'); // Redirect back to the cart
    }
  }, [dispatch, unbookedItems, navigate])

  const finalTotal = useSelector(selectCartFinalTotal);


  return (
    <div className="checkout-page">
      <h1 className="text-2xl font-bold mb-10 text-center">Réservation Sessions Mondial de l'Auto Paris</h1>
      <ProgressBar currentStep="3" />
      <div className="md:flex w-full">
        <div className="md:w-3/5 md:px-5">
          <PaymentForm finalTotal={finalTotal} />
        </div>
        <div className="md:w-2/5 md:mt-0 mt-8 md:px-4">
          <Summary />
        </div>
      </div>
    </div>
  )
}


export default CheckoutPage;