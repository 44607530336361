// src/store/bookingSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  date: null,
  time: null,
  groupSize: 1,
  childrenSize: 0,
  selectedOffer: { name: '', price: 0 },
  customers: [],
  finalTotal: 0,
  originalTotal: 0,
  isBooked: false,
  bookingData: {},
  eventDetails: {}
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    updateBookingDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetBooking: () => initialState,
  },
});

export const { updateBookingDetails, resetBooking } = bookingSlice.actions;
export default bookingSlice.reducer;
