// src/components/CustomerFormsList.js
import React, { useState, useEffect } from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronUpIcon } from  '@heroicons/react/24/solid';
import EmailChecker from './EmailChecker'; // Ensure EmailChecker is correctly imported
import CustomerForm from './CustomerForm'; // Ensure CustomerForm is correctly imported

const CustomerFormsList = ({ groupSize, customers, onCustomersChange, eventDetails }) => {
  /*const [customers, setCustomers] = useState(Array(groupSize).fill({
    email: '',
    tel: '',
    name: ''
  })); */
  //const [isCustomerChecked, setIsCustomerChecked] = useState(Array(groupSize).fill(false));
  const [isCustomerChecked, setIsCustomerChecked] = useState(() =>
    customers.map((customer) => !!customer.email)
  );
  //const [isCustomerFound, setIsCustomerFound] = useState(Array(groupSize).fill(false));
  const [isCustomerFound, setIsCustomerFound] = useState(() =>
    customers.map((customer) => !!customer.id)
  );

  const [searchedEmail, setSearchedEmail]  = useState(null);

  const [searchedEmails, setSearchedEmails]  =  useState(customers.map(customer => customer.email || null));
  
  const [errors, setErrors] = useState({});

  const handleEmailCheck = (index, customerData, email ='') => {
    const updatedCustomers = [...customers];
    const updatedIsCustomerChecked = [...isCustomerChecked];
    const updatedIsCustomerFound = [...isCustomerFound];

    updatedCustomers[index] = customerData;
    updatedIsCustomerChecked[index] = true;
    updatedIsCustomerFound[index] = !!customerData;

    //setCustomers(updatedCustomers);
    setIsCustomerChecked(updatedIsCustomerChecked);
    setIsCustomerFound(updatedIsCustomerFound);


    if (customerData) {
      onCustomersChange(updatedCustomers); 
    }

    setSearchedEmail(email);

    const updatedEmails = [...searchedEmails];
    updatedEmails[index] =  email || customerData?.email
    setSearchedEmails(updatedEmails);
    

    
  };

  const handleCustomerCreated = (index, customerData) => {
    const updatedCustomers = [...customers];
    updatedCustomers[index] = customerData;
    //setCustomers(updatedCustomers);
    onCustomersChange(updatedCustomers);
  };

  const handleCustomerChange = (index, field, value) => {
    const updatedCustomers = [...customers];

    updatedCustomers[index] = {
      ...updatedCustomers[index],
      [field]: value,
    };


     // Validate the unique value
    validateUniqueValues(index, field, value);


    //setCustomers(updatedCustomers);
   // onCustomersChange(updatedCustomers);
  };

  const validateUniqueValues = (index, field, value) => {
    let duplicateError = '';
  
    // Check the entered value against all other customer forms
    customers.forEach((customer, i) => {
      if (i !== index && customer[field] === value) {
        duplicateError = `Ce ${field} est déjà utilisé pour un autre participant`;
      }
    });
  
    // Update errors state
    setErrors((prevErrors) => ({
      ...prevErrors,
      [index]: {
        ...prevErrors[index],
        [field]: duplicateError,
      },
    }));
  };
  

  return (
    <div className="w-full">
      {Array.from({ length: groupSize }, (_, index) => (
        <Disclosure as="div" key={index} defaultOpen={ index==0 ? true : false}>
          {({ open }) => (
            <>
               <DisclosureButton className="mb-2 flex w-full justify-between rounded-lg bg-gold px-4 py-4 text-left text-sm font-medium text-black hover:bg-black hover:text-gold focus:outline-none focus-visible:ring focus-visible:ring-gold focus-visible:ring-opacity-75">
                <span>{`Participant ${index + 1}`}  {index == 0 ? "(obligatoire)" : ""} </span>
                <ChevronUpIcon
                  className={`${open ? 'transform rotate-180' : ''} w-5 h-5 `}
                />
              </DisclosureButton>
              <DisclosurePanel className="md:px-4 px-2 pt-4 pb-4 text-sm">
                {!isCustomerChecked[index] && (
                  <EmailChecker
                    onCustomerFound={(customerData) => handleEmailCheck(index, customerData)}
                    onCustomerNotFound={(email) => handleEmailCheck(index, null, email)} // Pass email when not found
                    searchedEmails= {searchedEmails}
                  />
                )}
                 {isCustomerChecked[index] && isCustomerFound[index] && customers.length > 0 &&(
                   <div className="p-4  border-2 border-gold rounded-md">
                    <p><strong>Nom:</strong> {customers[index].name}</p>
                    <p><strong>Email:</strong> {customers[index].email}</p>
                  </div>
                )}
                {(isCustomerChecked[index] && !isCustomerFound[index]) && (
                  <CustomerForm
                    onCustomerChange={(field, value) => handleCustomerChange(index, field, value)} // Real-time validation
                    onCustomerCreated={(customerData) => handleCustomerCreated(index, customerData)} // After API call
                    eventDetails={eventDetails}
                    customerExist={customers[index] || ''} // Auto-fill the email if provided
                    externalErrors={errors[index]}
                    searchedEmail = {searchedEmails[index]}
                    iterationIndex = {index}
                    required={index === 0} // First form is required
                  />
                )}
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};

export default CustomerFormsList;
