import React, { useEffect, useState } from 'react';
import { CheckCircleIcon } from  '@heroicons/react/24/solid';
import { Radio, RadioGroup } from '@headlessui/react'
import { useSelector, useDispatch } from 'react-redux';
import { fetchOffers, selectAllOffers, setSelectedOfferId, selectSelectedOffer } from '../store/offerSlice';



const OfferGroup = ({ onOfferChange,  selectedOffer }) => {

  //const [selectedOffer, setSelectedOffer] = useState({ name: '', price: 0, time: '' });
  const dispatch = useDispatch();
  const offers = useSelector(selectAllOffers);
  const selectedOfferId = useSelector(selectSelectedOffer);
  
  const [error, setError] = useState(null);

  const handleOfferSelection = (offer) => {
    //setSelectedOffer(offer);
    dispatch(setSelectedOfferId(offer.id)); 
    onOfferChange(offer);
  }

  const calculPrice = (price, is_kid, apply_discount = false) => {
    return is_kid ? parseInt((price * 0.85)) : ( apply_discount ? (price * 0.85).toFixed(2) : parseInt(price)) 
  }

  useEffect(() => {
    if (offers.length === 0) {
     dispatch(fetchOffers()); // Fetch offers when the component mounts
    }
  }, [dispatch, offers.length]);


  const loading = useSelector((state) => state.offers.status === 'loading');

  
  /*
  const offers = [
    { name: 'Initiation', time: '15min', price: 12 },
    { name: 'Racer (Course contre l\'IA)', time: '15min', price: 17 },
    { name: 'Hotlap - THE GRID', time: '30min', price: 33 }
  ];
*/

    if (error) {
      return <div>{error}</div>;
    }


  return (
    <div>
        <h2 className='mb-4 font-semibold text-sm'>Sélectionnez votre session :</h2>
        {loading ? (
        <div className="space-y-3">
          {/* Skeleton Loader */}
          {[1, 2, 3].map((_, index) => (
            <div key={index} className="animate-pulse flex space-x-4">
              <div className="rounded-lg bg-gray-200 h-12 w-full"></div>
            </div>
          ))}
        </div>
      ) : (
        <RadioGroup by="name"   value={selectedOffer}  onChange={handleOfferSelection} aria-label="Server size" className="space-y-3">
          {offers.map((offer) => (
              <Radio
                value={offer}
                key={offer.name}
                className="group relative flex cursor-pointer border-gray-300 border  rounded-lg  bg-white-500 py-3 px-5 pr-2  shadow-md transition focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:border-gold data-[checked]:border-2"
              >
                <div className="w-full">
                  <div className="flex w-full items-start  justify-between">
                    <div className="text-sm/6 flex flex-col w-32  xl:w-auto  md:w-48">
                      <p className="font-semibold">{offer.name}</p>
                      <div className="flex gap-2 text-gray-500">
                        <div>{offer.duration}min</div>
                        <div aria-hidden="true">&middot;</div>
                        <div>
                          {calculPrice(offer.price , offer.is_kid_friendly)}€ 
                          <span className="hidden xl:inline-block">
                            {offer.is_kid_friendly ? (
                              <span className="pl-1">prix enfants</span>
                              ) : (
                                <span className="pl-1">({calculPrice(offer.price , offer.is_kid_friendly, true)}€ prix enfants)</span>
                              ) 
                            }
                          </span>
                          </div>
                      </div>
                      <div>
                       <span className="xl:hidden text-gray-500">
                            {offer.is_kid_friendly ? (
                              <span className="pl-0">prix enfants</span>
                              ) : (
                                <span className="pl-0">({calculPrice(offer.price , offer.is_kid_friendly, true)}€ prix enfants)</span>
                              ) 
                            }
                          </span>
                      </div>
                    </div>

                    <p className="text-gray-600 text-sm mt-1 md:mt-0 hidden lg:block text-center px-2 md:w-40 lg:w-60 self-center">{offer.description}</p>

                    <div className={`flex justify-end w-44 flex-col text-sm ${offer.is_kid_friendly ? 'self-top' : 'self-center'}  `}>
                       {offer.is_kid_friendly && ( <div className="text-gray-700 mb-4 pl-3"> À partir de 5 ans.  </div>)}
                      <div className="flex justify-end gap-1 ">
                        <div className="flex items-center text-gray-700 px-2 ">
                          <img src="/images/heighticon.svg" alt="google Pay" className="h-4 w-auto" />
                          <span className="text-nowrap pl-2">{offer.additional_info}</span>
                          <div className="circleIcon">
                            <CheckCircleIcon className="size-6 fill-gold opacity-0 transition group-data-[checked]:opacity-100" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:hidden mt-2">
                    <p className="text-gray-600 text-sm">{offer.description}</p>
                  </div>
                </div>
              </Radio>
          ))}
        </RadioGroup>
        )}
    </div>
  )
}

export default OfferGroup;