import { addMinutes, parseISO } from 'date-fns';
import { formatDateTime } from '../utils/formatDateTime';
import { createBooking } from '../services/apiService';

export const bookOffer = async ({ customers, eventDetails, bookingdate, groupSize, childrenSize, offer }) => {
  const startDate = bookingdate;
  const endDate = addMinutes(parseISO(startDate), offer.duration);
  const formattedEndDate = formatDateTime(endDate);
  const primaryCustomerId = customers[0].id;
/*
  const participants = customers.length > 1 ? {
    customer_id: customers.slice(1).map(customer => customer.id)
  } : null;
  */
  const participants = customers.length > 1 ? 
  customers.slice(1).map(customer => ({ customer_id: customer.id })) : null;

  const bookingData = {
    event_name: eventDetails.eventName,
    customer_id: primaryCustomerId,
    start_time: startDate,
    end_time: formattedEndDate,
    offer_id: offer.id,
    status: 'Holding',
    venue_id: eventDetails.venueId,
    group_size: groupSize,
    children_size: childrenSize,
    ...(participants && {participants})
  };

  const data = await createBooking(bookingData);
  return data.booking;
}


export const bookedError = (error) => {
  let message = 'La création de la réservation a échoué.';
  const codesErrors = ["BOOKING_CONFLICT", "SIMULATOR_UNAVAILABLE"];
  if (error && typeof error === 'object' && 'response' in error) {
    console.error('Error:', error.response.data);
    if ('code' in error.response.data &&  codesErrors.includes(error.response.data.code)) {
      message = error.response.data.message;
    }
  } 
  return message;
} 