import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartItems: [],
  finalTotal: 0,
  paymentIntentId: null,
  paymentStatus: 'pending', // or 'success', 'failed'
};

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    storePurchaseDetails(state, action) {
      const { cartItems, finalTotal, paymentIntentId } = action.payload;
      state.cartItems = cartItems;
      state.finalTotal = finalTotal;
      state.paymentIntentId = paymentIntentId;
      state.paymentStatus = 'success';
    },
    clearPurchase(state) {
      state.cartItems = [];
      state.finalTotal = 0;
      state.paymentIntentId = null;
      state.paymentStatus = 'pending';
    },
  },
});

export const { storePurchaseDetails, clearPurchase } = purchaseSlice.actions;

export default purchaseSlice.reducer;
