// src/components/ProgressBar.js
import React from 'react';

const ProgressBar = ({ currentStep }) => {
  const steps = [
    { name: 'Réservation', id: 1 },
    { name: 'Coordonnées', id: 2 },
    { name: 'Paiement', id: 3 },
  ];


  return (
    <div className="flex justify-between items-center mb-6 md:px-20 mx-auto w-full max-w-screen-lg">
      {steps.map((step, index) => (
        <div key={step.id} className={` ${index < steps.length - 1 ? 'flex-1' : ''} flex  items-center`}>
          <div className="relative flex flex-col items-center">
            <div
              className={`rounded-full h-8 w-8 flex items-center justify-center ${
                currentStep >= step.id ? 'bg-gold text-black' : 'bg-gray-200 text-gray-500'
              }`}
            >
              {step.id}
            </div>
            <span className={`mt-2 text-sm ${currentStep >= step.id ? 'text-black' : 'text-gray-500'}`}>
              {step.name}
            </span>
          </div>
          {index < steps.length - 1 && (
            <div
              className={`flex-1 h-0.5 w-11 md:w-full ${
                currentStep > step.id ? 'bg-gold' : 'bg-gray-200'
              }`}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
