import React from 'react';

const CGV = () => {
  return (
    <div className="mx-auto p-6">
      <h1 className="text-2xl font-bold mb-10 text-center">Conditions Générales de Vente (CGV)</h1>
        <h2 className="text-xl font-semibold mb-2">1. Champ d’application</h2>
        <div className="mb-6">
          <p className="mb-4">Les présentes Conditions Générales de Vente (ci-après les « CGV ») s’appliquent sans restriction, ni réserve à toutes les transactions conclues entre <strong>E-MOTION</strong>, société par actions simplifiée au capital de 1 000,00 €, dont le siège est situé 20 bis rue Louis-Philippe, 92200 Neuilly-sur-Seine, immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro 929 883 395 (ci-après « <strong>E-MOTION</strong> »), et toute personne désirant effectuer une réservation via le site www.<strong>E-MOTION</strong>.racing (ci-après le « Site »). Toute réservation implique l’acceptation préalable, pleine et entière des présentes CGV. Celles-ci ont notamment pour objet de décrire les conditions de réservation et de paiement des Prestations vendues par l’intermédiaire du Site.</p>

          <p className="mb-4">Les présentes CGV sont accessibles à tout moment sur le Site. Elles peuvent faire l’objet de modifications par <strong>E-MOTION</strong>. Les CGV ainsi modifiées seront applicables à toute réservation effectuée postérieurement à la mise en ligne des CGV.</p>

          <p className="mb-4">Le Client déclare avoir la pleine capacité juridique lui permettant de s’engager et de procéder à toute Réservation sur le Site.</p>

          <p className="mb-4">Les ventes de Produits font l’objet de conditions différentes. </p>
        </div>

        <h2 className="text-xl font-semibold mb-2">2. Définitions</h2>
        <div className="mb-6">
          <p>Les termes ci-après ont, dans les présentes CGV, la signification suivante :</p>
          <p><strong>Client(s) :</strong> désigne indistinctement le Client consommateur et le Client professionnel, sauf précision contraire. </p>
          <p><strong>Client consommateur :</strong> désigne toute personne physique qui agit à des fins qui n’entrent pas dans le cadre de son activité commerciale, industrielle, artisanale, libérale ou agricole, procédant à une Réservation sur le Site.</p>
          <p><strong>Client professionnel :</strong> désigne toute personne physique ou morale ayant la qualité de professionnel, procédant à une Réservation sur le Site, et qui agit à des fins professionnelles. </p>
          <p><strong>Parties :</strong> désigne ensemble la société <strong>E-MOTION</strong> et le Client. </p>
          <p><strong>Prestation(s) :</strong> désigne les activités proposées à la réservation sur le Site, notamment les sessions de simulation de conduite.</p>
          <p><strong>Réservation :</strong> désigne tout achat de Prestation réalisé par un Client sur le Site.</p>
          <p><strong>Site :</strong> désigne le site accessible à l’adresse www.<strong>E-MOTION</strong>.racing.</p>
          <p><strong>Stand <strong>E-MOTION</strong> :</strong> désigne le lieu d’exécution de la Réservation situé Paris Expo Porte de Versailles, Hall</p>
          <p><strong>Utilisateur :</strong> désigne toute personne qui navigue sur le Site. </p>
        </div>

        <h2 className="text-xl font-semibold mb-2">3. Réservation</h2>
        <div className="mb-6">
          <h3 className="text-l font-semibold mb-2"> 3.1 Caractéristiques des Prestations proposées </h3>
          <p className="mb-4">Chaque Prestation se matérialise par des temps de simulation de conduite en minutes. Ces minutes sont indivisibles et commencent à courir une fois la simulation démarrée. </p>

          <p><strong>E-MOTION</strong> propose les offres suivantes : </p>
          <table class="table-fixed border-separate border-spacing-4">
            <tbody>
              <tr>
                <th className="text-left">INITIATION</th>
                <td> 15 min</td>
                <td> 12 €</td>
              </tr>
              <tr>
               <th className="text-left">RACER</th>
                <td> 15 min</td>
                <td> 17 €</td>
              </tr>
              <tr>
                <th className="text-left">HOTLAP - THE GRID</th>
                <td> 30 min</td>
                <td> 33 €</td>
              </tr>
              <tr>
                <th className="text-left">HOTLAP KARTING – THE GRID</th>
                <td> 15 min</td>
                <td> 10 €</td>
              </tr>
            </tbody>
          </table>


          <p className="mb-2">Une réduction de -15% est appliquée sur les tarifs ci-dessus :</p>
          <ul className="list-disc list-outside pl-4 mb-4">
              <li>
            pour les enfants de moins de 14 ans, sur présentation de justificatif ;</li>
            <li> pour les groupes d’au moins 4 personnes réservant une même offre pour tous les participants.</li>
          </ul>
          <p>Le Client s’engage à arriver au moins 10 min avant que sa session ne commence. </p>

        </div>

        <div className="mb-6">
          <h3 className="text-l font-semibold mb-2">3.2 Procédure de réservation</h3>
          <p className="mb-2">Afin de réserver une Prestation, le Client doit se rendre sur le Site et suivre les étapes suivantes : </p>
          <ul className="list-decimal list-outside pl-4 mb-4">
            <li>Sélectionner « Réserver votre session » et choisir une date et un horaire ; </li>
            <li>Sélectionner le nombre de participants (1 à 10) ; </li>
            <li>Renseigner les informations requises sur les participants ; </li>
            <li>Vérifier le récapitulatif de réservation ; </li>
            <li>Cliquer sur « Paiement » et procéder au paiement.</li>
          </ul>
         
          <p className="mb-4">Le Client recevra un email ou un SMS de confirmation de paiement. </p>

          <p className="mb-4">La Réservation ne sera considérée comme valide qu’après paiement intégral du prix. </p>

          <p className="mb-4">Toute Réservation validée emporte la formation d’un contrat conclu entre le Client et <strong>E-MOTION</strong>. </p>
            </div>
        <div className="mb-6">
          <h3 className="text-l font-semibold mb-2"> 3.3 Prix et paiement </h3>
       
            <p className="mb-2"> Les prix affichés sur le Site incluent la taxe sur la valeur ajoutée et sont exprimés en euros. Toute modification du taux applicable peut impacter le prix des Prestations à compter de la date d’entrée en vigueur du nouveau taux. </p> 

            <p className="mb-2">Le prix applicable est celui indiqué sur le Site à la date à laquelle la Réservation est passée par le Client. </p> 

            <p className="mb-2">Les paiements ne pourront être réalisés qu’au moyen des modes de paiement acceptés par le Site. </p> 

            <p className="mb-2"><strong>E-MOTION</strong> se réserve le droit de refuser, suspendre ou annuler toute Réservation pour motif légitime notamment en cas d’incident de paiement, ou de réservation anormale ou frauduleuse. <strong>E-MOTION</strong> se réserve par ailleurs le droit de refuser toute Réservation d’un Client avec lequel il existerait un litige relatif au paiement d’une précédente réservation. </p> 

            <p className="mb-2"><strong>E-MOTION</strong> se réserve le droit de demander au Client de justifier de son identité lors du paiement ainsi que de sa capacité à engager la société pour laquelle il contracte le cas échéant. A défaut de réception des justificatifs demandés, <strong>E-MOTION</strong> se réserve le droit d’annuler ou de refuser la Réservation concernée.</p> 

            <p className="mb-2">Tout retard dans le paiement d’une Réservation par un Client professionnel entraîne de plein droit, le jour suivant la date d’exigibilité du paiement, l’application d’intérêts de retard d’un montant équivalent à trois fois le taux d’intérêt légal ainsi que d’une indemnité forfaitaire pour frais de recouvrement d’un montant de 40 euros.  Si les frais de recouvrement exposés sont supérieurs au montant de cette indemnité, <strong>E-MOTION</strong> pourra demander une indemnisation complémentaire sur présentation de justificatifs.</p> 

            <p className="mb-2">Le Client ne pourra prétendre à aucun remboursement de sa Réservation dans l’hypothèse où il ne se présenterait pas sur le Stand <strong>E-MOTION</strong> aux jour et horaire de Réservation, de même que s’il se présente en retard.</p> 

            <p className="mb-2">En cas de retard, le Client reconnaît que chaque minute peut être décomptée de sa Réservation.  Si son retard est supérieur au temps de Réservation, celle-ci sera réputée consommée. </p> 
          </div>

          <h2 className="text-xl font-semibold mb-2">4. Modification de la réservation / Saisie des participants</h2>
          <p className="mb-6">Le Client a la possibilité de demander une annulation ou une modification (date, horaire, nombre de participants) de sa Réservation directement sur le Stand <strong>E-MOTION</strong>. <strong>E-MOTION</strong> ne sera pas tenue d’accepter ladite demande de modification et d’annulation. En cas d’impossibilité pour <strong>E-MOTION</strong> de procéder à la modification ou à l’annulation demandée par le Client, ce dernier ne pourra prétendre à aucun remboursement ou indemnité.  </p>


          <h2 className="text-xl font-semibold mb-2">5. Service Clients</h2>
          <p className="mb-6">Pour toute information, question ou réclamation, le Service Clients est joignable via le formulaire de contact disponible sur le Site.</p>

          <h2 className="text-xl font-semibold mb-2">6. Responsabilités</h2>
          <div className="mb-6">
            <h3 className="text-l font-semibold mb-2"> 6.1 Le Client est tenu de vérifier l’exhaustivité et la véracité des renseignements qu’il fournit lors d’une Réservation sur le Site. </h3>
            <p className="mb-4"> Le Client reste responsable de toute erreur de saisie de sa part. La responsabilité de <strong>E-MOTION</strong> ne saurait être recherchée de ce fait.</p>
            <h3 className="text-l font-semibold mb-2">6.2 La saisie par le Client de ses informations de paiement doit se faire dans une stricte discrétion et à l’abri des regards. Le Client doit apporter toutes les précautions nécessaires à cet effet. </h3>
            <h3 className="text-l font-semibold mb-2">6.3 <strong>E-MOTION</strong> ne pourra être tenue responsable si la non-exécution ou le retard dans l’exécution de l’une quelconque de ses obligations telles que décrites aux présentes découle d’un cas de force majeure au sens de l’article 1218 du Code civil et de la jurisprudence applicable. </h3>


            <p className="mb-2">Constituent un cas de force majeure les événements suivants notamment :</p>
            <ul className="list-disc list-outside pl-4 mb-4">
              <li>Grèves, guerres, émeutes, révolutions, embargo ;</li>
              <li>Aléas sanitaires ou climatiques exceptionnels ;</li>
              <li>Aléas de toute nature affectant le site d’implantation des simulateurs ;</li>
              <li>Epidémie/pandémie entendue comme le développement et la propagation d’une maladie sur le territoire national ;</li>
              <li>Mesures d’interdiction et de restriction des déplacements des personnes et des véhicules ;</li>
              <li>Défaillance de tout partenaire ou prestataire, y compris technique, impliqué dans le bon fonctionnement des simulateurs et des réservations en ligne ;</li>
              <li>Perte d’une autorisation administrative ;</li>
              <li>Virus informatique ou cyberattaque ;</li> 
              <li> Annulation totale ou partielle de l’évènement pour des circonstances indépendantes de la volonté d’<strong>E-MOTION</strong>.</li>
            </ul>

            <p className="mb-4">En cas de survenance d’un cas de force majeure, <strong>E-MOTION</strong> informera le Client de son impossibilité à exécuter ses obligations. La suspension des obligations ne pourra en aucun cas être une cause de responsabilité pour non-exécution ou retard dans l’exécution de l’obligation concernée, ni induire le versement de dommages et intérêts ou pénalités de retard. </p>
            <p className="mb-4">L’exécution de l’obligation est suspendue pendant toute la durée de la force majeure si elle est temporaire, et ne dépasse pas une durée de 24 heures. Par conséquent, dès la disparition de la cause de la suspension de ses obligations, <strong>E-MOTION</strong> fera ses meilleurs efforts pour reprendre le plus rapidement possible l’exécution normale de ses obligations. </p>

            <p className="mb-4">En tout état de cause, la responsabilité de <strong>E-MOTION</strong> ne pourra être engagée pour tout dommage qui ne résulterait pas d’un manquement direct à l’une de ses obligations. </p>
          </div>

          <h2 className="text-xl font-semibold mb-2">7. Propriété intellectuelle</h2>

          <p className="mb-6">Le Site et les éléments qu’il contient sont la propriété exclusive de la société <strong>E-MOTION</strong> ou de ses partenaires. Tout Utilisateur du Site s’interdit donc toute reproduction ou exploitation des éléments de propriété intellectuelle que contient le Site sans l’autorisation expresse, écrite et préalable de la société <strong>E-MOTION</strong>. </p>

          <h2 className="text-xl font-semibold mb-2">8. Données personnelles</h2>

          <div className="mb-6">
            <h3 className="text-l font-semibold mb-2">8.1 Opposition au démarchage téléphonique – BLOCTEL</h3>
            <p className="mb-6">Le Client est informé qu’il peut gratuitement s’inscrire sur une liste d’opposition au démarchage téléphonique afin de ne pas recevoir de prospection commerciale.</p>

            <h3 className="text-l font-semibold mb-2">8.2 Traitement des données personnelles collectées</h3>
            <p className="mb-4">Les données personnelles recueillies auprès du Client font l’objet d’un traitement informatique réalisé par la société <strong>E-MOTION</strong>. Elles sont enregistrées dans son fichier clients et sont indispensables au traitement de la Réservation. Elles seront conservées aussi longtemps que nécessaire pour l’exécution de la Réservation et pour permettre à la société <strong>E-MOTION</strong> de respecter ses obligations légales et règlementaires. </p>

            <p className="mb-4">L’accès aux données personnelles du Client est strictement limité aux employés du responsable de traitement habilités à les traiter en raison de leurs fonctions. Les informations recueillies pourront être communiquées aux partenaires et prestataires de la société E- MOTION en charge de l’exécution de la Réservation, du paiement ou de la facturation, sans que l’autorisation du Client ne soit nécessaire. Ces derniers ne disposent que d’un accès limité aux données et ont l’obligation de les utiliser en conformité avec les dispositions de la législation applicable en matière de protection des données personnelles. </p>

            <p className="mb-4">Avec l’accord exprès du Client, la société <strong>E-MOTION</strong> pourra, directement ou par l’intermédiaire de ses sous-traitants ou partenaires, envoyer au Client des newsletters l’informant des opérations commerciales et événements organisés par la société <strong>E-MOTION</strong>.</p>
            <p className="mb-4">Le Client conserve toutefois la possibilité de refuser de telles newsletters, soit en ne donnant pas son consentement lors de l’inscription ou d’une Réservation sur le Site ; soit, si le Client a donné son consentement au préalable, en se désabonnant en cliquant sur le lien de désabonnement présent dans chaque communication, soit par écrit en envoyant un courrier à l’adresse précisée ci-après. </p>

            <p className="mb-4">Les données recueillies ne seront pas transférées en dehors de l’Union Européenne. Si elles devaient l’être, le Client en sera informé et les garanties prises afin de sécuriser le transfert et le traitement des données seront précisées au Client. </p>

            <p className="mb-4"> Conformément à la règlementation applicable, toute personne physique dispose d’un droit d’accès, de rectification, d’effacement, et de portabilité de ses données, ainsi que du droit de s’opposer au traitement pour motif légitime, droits qu’elle peut exercer en s’adressant à la société <strong>E-MOTION</strong> en envoyant un courrier à l’adresse suivante : <strong>E-MOTION</strong>, Service DPO, 20 bis rue Louis-Philippe, 92200 Neuilly-sur-Seine ou par email :  dpo@car-lovers.com.</p>
          </div>
          <h2 className="text-xl font-semibold mb-2">9. Médiation</h2>
          <div className="mb-6">
            <p className="mb-2">Conformément aux articles L.612-1 et suivants du Code de la consommation, le Client peut recourir gratuitement au service de médiation de MOBILIANS dont <strong>E-MOTION</strong> relève :</p>
            <ul className="list-disc list-outside pl-4 mb-4">
              <li>Soit par internet https://www.mediateur-mobilians.fr, en complétant le formulaire dédié à cet effet, et en fournissant les documents étayant sa demande ;</li>
              <li>Soit par courriel à l’adresse mediateur@mediateur-mobilians.fr </li>
              <li>Soit par courrier postal adressé à M. le Médiateur de Mobilians, 43 bis route de Vaugirard - CS 80016 -92197 Meudon CEDEX, accompagné des documents étayant sa demande. </li>
            </ul> 

            <p className="mb-4">Le Client est informé qu’il existe une plateforme européenne de règlement en ligne des litiges, conformément aux dispositions de l’article 14 du règlement UE 524/2013 laquelle est accessible au lien suivant : https://ec.europa.eu/consumers/odr/</p>

            <p className="mb-2">Pour que sa demande soit recevable, le Client doit notamment justifier des conditions suivantes : </p>
            <ul className="list-disc list-outside pl-4 mb-4">
              <li>Une tentative préalable de résolution amiable de son litige avec <strong>E-MOTION</strong> au moyen d’une réclamation écrite ; </li>
              <li>Avoir introduit sa demande auprès du médiateur dans un délai inférieur à un an à compter de sa réclamation écrite auprès de <strong>E-MOTION</strong>.</li>
            </ul>  
          </div>

          <h2 className="text-xl font-semibold mb-2">10. Personnes mineures</h2>
          <div className="mb-6">
            <p className="mb-4">La personne mineure doit recueillir, préalablement à toute réservation, l’autorisation de ses parents, tuteurs, ou représentants légaux. </p>
            <p className="mb-4">Elle devra également être accompagnée d’une personne majeure sur le lieu d’exécution de la Réservation. </p>
           </div>

           <h2 className="text-xl font-semibold mb-2">11. Règles applicables et conditions de sécurité sur le Stand <strong>E-MOTION</strong></h2>
           <div className="mb-6">
              <p className="mb-4"><strong>E-MOTION</strong> se réserve le droit de refuser l’accès aux simulateurs à toute personne ayant un comportement incompatible avec l’activité planifiée. </p>
              <p className="mb-4">Le Client est informé qu’il ne doit présenter aucune contre-indication médicale à la pratique de la conduite.  </p>
              <p className="mb-4">Le Client devra se conformer à l’ensemble des règles de sécurité liées aux Prestations réservées, aux règles d’utilisation des simulateurs ainsi qu’à toutes les instructions données par les instructeurs au cours des sessions.  </p>
              <p className="mb-4">Le Client devra porter une tenue en adéquation avec l’activité réservée qui assure sa propre sécurité et celle des autres. Notamment, les tongs et chaussures à talons sont interdites. La responsabilité d’<strong>E-MOTION</strong> ne saurait être engagée d’aucune façon de ce fait.   </p>

              <p className="mb-2">L'usage des simulateurs est strictement interdit : </p>
              <ul className="list-disc list-outside pl-4 mb-4">
                <li>aux personnes de taille inférieure à 1m35 sur les simulateurs « ULTIMATE » ;</li>
                <li>aux personnes de taille supérieure à 1m35 pour les simulateurs « KARTING » ;</li>
                <li>aux personnes souffrant de problèmes cardiaques ; </li>
                <li>aux personnes épileptiques.</li>
              </ul>
              <p className="mb-4">L’usage des simulateurs n’est pas recommandé pour les personnes souffrant de cinétose (mal de transport), de nausées, de vertiges ainsi qu’aux femmes enceintes. </p>
              <p className="mb-4">La mise à disposition du simulateur pourra être annulée ou interrompue à tout moment si les conditions de sécurité ou d’hygiène ne sont plus réunies.  </p>
              <p className="mb-4">En cas de non-respect des consignes, la responsabilité de la société <strong>E-MOTION</strong> ne pourra en aucun cas être engagée. </p>
              <p className="mb-4">Tout manquement pourra entrainer l’annulation de la Réservation passée par le Client, sans remboursement ni indemnité. </p>
            </div>
            <h2 className="text-xl font-semibold mb-2">12. Droit applicable et juridiction compétente</h2>
            <div className="mb-6">
              <p className="mb-4">Les présentes CGV, la Réservation et tous les actes qui en découlent seront interprétés et exécutés conformément au droit français. </p>
              <p className="mb-4">Les parties sont invitées à rechercher, avant tout contentieux, un éventuel accord amiable. Pour toute réclamation, le Client peut contacter la société <strong>E-MOTION</strong> aux coordonnées indiquées à l’article 5. Le Client consommateur peut recourir à la médiation dans les conditions de l’article 9. </p>
              <p className="mb-4">A défaut d’accord amiable, tout litige de toute nature ayant trait à la Réservation ou aux présentes CGV relèveront de la compétence exclusive des Tribunaux français. </p>
            </div>
    </div>
  );
};

export default CGV;
