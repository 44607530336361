import axios from 'axios';
import { XMLParser } from 'fast-xml-parser';

const BASE_URL = process.env.REACT_APP_BASE_URL;


export const checkCustomer = async (email) => {
  try {
    const response = await axios.get(`${BASE_URL}/customers`, {
      params: { 
        email: email
      }
    });
    return response.data; // Assuming your backend returns JSON
  } catch (error) {
    console.error('Error checking customer:', error);
    throw error; // Rethrow the error to handle it in your components
  }
};

export const createCustomer = async (customerData) => {
  try {
    const response = await axios.post(`${BASE_URL}/customers`, customerData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data; // Assuming your backend returns JSON
  } catch (error) {
    console.error('Error creating customer:', error);
    throw error; // Rethrow the error to handle it in your components
  }
};

export const createBooking = async (bookingData) => {
  try {
    const response = await axios.post(`${BASE_URL}/bookings`, bookingData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data; // Assuming your backend returns JSON
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error; // Rethrow the error to handle it in your components
  }
};


export const getOffers = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/offers`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch offers');
  }
};


export const getAvailableTimeSlots = async (date, offerId, groupSize, venueId) => {
  try {
    const response = await axios.get(`${BASE_URL}/available-time-slots`, {
      params: {
        date,
        offer_id: offerId,
        group_size: groupSize,
        venue_id: venueId,
      }
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch available-time-slots');
  }
};


// Payment API call (Stripe or Sylq)
export const createPayment = async (paymentData) => {
  try {
    const response = await axios.post(`${BASE_URL}/payment`, paymentData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to create payment');
  }
};


export const updatePaymentStatus = async (paymentId, status) => {
  try {
    const response = await axios.put(`${BASE_URL}/payments/${paymentId}/status`, { status });
    return response.data;
  } catch (error) {
    throw new Error('Failed to update payment status');
  }
};


export const fetchAvailableDates = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/calendar/available-dates`);
    return response.data.availableDates;
  } catch (error) {
    throw new Error('Failed to fetch available dates');
  }
};

const responseXMLTOJSON = (responseData) => {
  const parser = new XMLParser();
  return parser.parse(responseData);
}