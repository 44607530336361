// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import bookingReducer from './bookingSlice';
import cartReducer from './cartSlice';
import purchaseReducer from './purchaseSlice';
import offerReducers from './offerSlice';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web



// Create a persist configuration
const persistConfig = {
  key: 'root', // The key for localStorage
  storage,
  whitelist: ['cart'], // List of reducers to persist (e.g., 'cart')
};

// Combine all reducers
const rootReducer = combineReducers({
  booking: bookingReducer,
  cart: cartReducer,
  purchase: purchaseReducer,
  offers: offerReducers
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // This is important for redux-persist
    }),
});

// Create the persistor
export const persistor = persistStore(store);

export default store;
