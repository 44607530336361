// src/store/cartSlice.js
import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
  },
  reducers: {
    addItemToCart(state, action) {
      state.items.push(action.payload);
    },
    removeItemFromCart(state, action) {
      state.items = state.items.filter((item, index) => index !== action.payload);
    },
    clearCart(state) {
      state.items = [];
    },
    updateCartItemAsBooked(state, action) {
      const { index, bookingData, error } = action.payload;
      if (state.items[index]) {
        state.items[index].isBooked = !error;
        state.items[index].bookingData = bookingData || null;
        state.items[index].error = error || null;
      }
    },
  },
});

export const selectCartFinalTotal = (state) =>
  state.cart.items.reduce((total, item) => total + item.finalTotal, 0);

export const selectCartItemSize = (state) =>
  state.cart.items.length

export const selectBookingIds = (state) =>
  state.cart.items.map(item => item.bookingData.id);


export const selectCustomerId = (state) =>
  state.cart.items.length > 0 ? state.cart.items[0].customers[0].id : null;

export const { addItemToCart, removeItemFromCart, clearCart, updateCartItemAsBooked } = cartSlice.actions;
export default cartSlice.reducer;
